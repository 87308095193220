<template>
  <div :class="`d-flex ${isEdit ? 'flex-column align-items-center' : ''}`">
    <b-col v-if="subCategories && !isEdit" md="4">
      <b-form-group label="Product Sub Categories" label-for="sub-category">
        <v-select
          id="sub-category"
          v-model="selectedCategory"
          :options="subCategories.data.productSubCategories"
          value="productSubCategoryId"
          label="productSubCategoryNameEng"
          :reduce="(option) => option.productSubCategoryId"
        >
          <template slot="option" slot-scope="option">
            <span style="font-size: 12px">
              {{ option.productSubCategoryNameGeo }} / {{ option.productSubCategoryNameEng }}
            </span>
          </template>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col md="8">
      <div class="save-wrapper">
        <div class="d-flex">
          <b-form-group label="Geo name" label-for="attribute-name" class="mr-1 w-100">
            <b-form-input
              id="attribute-name"
              v-model="productAttribute.nameGeo"
              type="text"
              :disabled="!selectedCategory"
              placeholder="Enter attribute"
            />
          </b-form-group>
          <b-form-group label="Eng name" label-for="attribute-name" class="w-100">
            <b-form-input
              id="attribute-name"
              v-model="productAttribute.nameEng"
              :disabled="!selectedCategory"
              type="text"
              placeholder="Enter attribute"
            />
          </b-form-group>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <b-form-checkbox v-model="productAttribute.isConfigurable" :value="true" :disabled="!selectedCategory">
            Is configurable
          </b-form-checkbox>
          <b-button variant="primary" pill :disabled="isBtnDisabled" @click="save()">
            <span>{{ isEdit ? "Update" : "Save" }}</span>
          </b-button>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import { BCol, BFormGroup, BFormInput, BButton, BFormCheckbox } from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormCheckbox,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  props: {
    refreshTable: {
      type: Function,
      default: () => {},
    },
    attribute: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attr: null,
      selectedCategory: null,
      productAttribute: {
        nameEng: "",
        nameGeo: "",
        isConfigurable: false,
      },
      productAttributes: [],
    };
  },
  computed: {
    ...mapState("parametersModule", ["subCategories"]),
    isBtnDisabled() {
      return this.productAttribute.nameEng === "" || this.productAttribute.nameGeo === "";
    },
  },
  created() {
    this.handleEditFields();
  },
  methods: {
    ...mapActions("parametersModule", ["createProductAttributes", "updateProductAttributes"]),
    handleEditFields() {
      console.log("hit this");
      if (this.attribute && this.isEdit) {
        console.log("hit this inside");
        this.attr = this.attribute;
        this.productAttribute = {
          nameEng: this.attr.nameEng,
          nameGeo: this.attr.nameGeo,
          isConfigurable: this.attr.isConfigurable,
        };
        this.selectedCategory = this.attr.subCategoryId;
      }
    },
    resetForm() {
      this.productAttribute = {
        nameEng: null,
        nameGeo: null,
        isConfigurable: false,
      };
      this.productAttributes = [];
    },
    addAttribute() {
      this.productAttributes.push({
        nameEng: this.productAttribute.nameEng,
        nameGeo: this.productAttribute.nameGeo,
        isConfigurable: this.productAttribute.isConfigurable,
      });
      this.productAttribute = {};
    },
    fireToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant: "success",
        },
      });
    },
    save() {
      if (this.isEdit) {
        this.updateProductAttributes({
          id: this.attribute.id,
          nameEng: this.productAttribute.nameEng,
          nameGeo: this.productAttribute.nameGeo,
          isConfigurable: this.productAttribute.isConfigurable,
        }).then(() => {
          this.refreshTable();
          this.resetForm();
          this.fireToast("Successfully edited.");
          this.$emit("close")
        });
      } else {
        this.addAttribute();
        this.createProductAttributes({
          id: this.selectedCategory,
          productAttributes: this.productAttributes,
        }).then(() => {
          this.refreshTable();
          this.resetForm();
          this.fireToast("Successfully added.");
          this.$emit("close")
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #636363;
  font-size: 16px;
}

.selected-title {
  color: #636363;
  font-size: 13px;
}

.padding-zero {
  padding: 0;
}

.save-wrapper {
  display: flex;
  flex-direction: column;
}
</style>
