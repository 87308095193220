<template>
  <div
    :class="`d-flex align-items-center ${type !== 'districts' ? 'mb-1' : ''}`"
  >
    <b-form-input
      id="region-name"
      v-model="geoName"
      type="text"
      size="sm"
      :placeholder="$t('global.nameGeo')"
      class="mr-1"
    />
    <b-form-input
      id="region-name"
      v-model="engName"
      type="text"
      size="sm"
      :placeholder="$t('global.nameEng')"
      class="mr-1"
    />
    <b-button
      variant="success"
      style="padding: 0.4rem"
      @click="updateFunction(geoName, engName, id, type)"
    >
      <feather-icon icon="SaveIcon" size="12" class="align-text-top mb-0" />
    </b-button>
  </div>
</template>

<script>
import { BFormInput, BButton } from "bootstrap-vue";
import "vue-good-table/dist/vue-good-table.css";

export default {
  components: {
    BFormInput,
    BButton,
  },
  props: {
    nameGeo: {
      type: String,
      default: "",
    },
    nameEng: {
      type: String,
      default: "",
    },
    updateFunction: {
      type: Function,
      default: () => {},
    },
    id: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "",
    },
  },
  computed: {
    engName: {
      get() {
        return this.nameEng;
      },
      set(val) {
        this.$emit("update:nameEng", val);
      },
    },
    geoName: {
      get() {
        return this.nameGeo;
      },
      set(val) {
        this.$emit("update:nameGeo", val);
      },
    },
  },
};
</script>
