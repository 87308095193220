<template>
  <b-card>
    <CreatePopup popup-name="region" />
    <b-button
      class="city-button mb-2"
      variant="primary"
      @click="showCreatePopUp"
    >
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>{{ $t("regions.createRegion") }}</span>
    </b-button>
    <RegionInfoPopup />
  </b-card>
</template>

<script>
import { BCard, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapState } from "vuex";
import RegionInfoPopup from "./RegionInfoPopup.vue";
import CreatePopup from "./CreatePopup.vue";

export default {
  components: {
    BButton,
    RegionInfoPopup,
    CreatePopup,
    BCard,
  },
  data() {
    return {
      regionsList: null,
      selectedRegionId: null,
      fields: [
        {
          key: "regionId",
          label: "ID",
        },
        {
          key: "regionNameEng",
          label: "Region",
          formatter: (value, key, item) =>
            `${item.regionNameGeo} / ${item.regionNameEng}`,
        },
        {
          key: "cities[0].cityNameEng",
          label: "City",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  computed: {
    ...mapState("parametersModule", ["regions", "region"]),
  },
  created() {
    this.getRegion({ id: 1 });
  },
  methods: {
    ...mapActions("parametersModule", [
      "getRegions",
      "getRegion",
      "deleteRegionParameters",
    ]),
    showCreatePopUp() {
      this.$bvModal.show("create-modal");
    },
    toast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "FileIcon",
          variant: "success",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollme {
  overflow-x: auto;
}
</style>
