<template>
  <div class="inputs-wrapper">
    <!-- Region Name -->
    <b-col class="padding-zero">
      <b-form-group label="Country Name" label-for="region-name">
        <div class="d-flex">
          <b-form-input
            id="region-name"
            v-model="region.regionNameGeo"
            type="text"
            placeholder="Geo name"
            class="mr-1"
          />
          <b-form-input
            id="region-name"
            v-model="region.regionNameEng"
            type="text"
            placeholder="Eng name"
          />
        </div>
      </b-form-group>
    </b-col>
    <b-col class="padding-zero">
      <div class="city-container">
        <label>{{
          `City in ${region.regionNameGeo} / ${region.regionNameEng}`
        }}</label>
        <b-form-group abel-for="city-name">
          <div class="d-flex">
            <b-form-input
              v-model="region.cityNameGeo"
              :placeholder="$t('global.nameGeo')"
              label="Cities"
              :disabled="!region.regionNameEng"
              label-for="Cities"
              class="mr-1"
              @keyup.enter="addCityName"
            />
            <b-form-input
              v-model="region.cityNameEng"
              :placeholder="$t('global.nameEng')"
              label="Cities"
              :disabled="!region.regionNameEng"
              label-for="Cities"
              class="mr-1"
              @keyup.enter="addCityName"
            />
          </div>
        </b-form-group>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="city-button mb-2"
          variant="primary"
          :disabled="!region.cityNameGeo && !region.citynameEng"
          @click="addCityName"
          @keyup.enter="addCityName"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>{{ $t("global.add") }}</span>
        </b-button>
      </div>
    </b-col>
    <div
      v-for="city in region.cities"
      :id="city.id"
      :key="city.id"
      class="w-100"
    >
      <p>
        {{ `${city.cityNameGeo} / ${city.cityNameEng}` }}
      </p>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="save"
    >
      <span>{{ $t("global.submit") }}</span>
    </b-button>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BCol, BButton } from "bootstrap-vue";
// import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  //   mixins: [heightTransition],
  data() {
    return {
      region: {
        cityNameEng: "",
        cityNameGeo: "",
        regionNameEng: "Georgia",
        regionNameGeo: "საქართველო",
        coordinates: "",
        cities: [],
      },
    };
  },
  computed: {
    ...mapState("parametersModule", ["regions"]),
  },
  watch: {
    "region.nameEng": {
      handler(val) {
        this.region.regionGeo = val;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("parametersModule", ["createRegion", "getRegions"]),
    addCityName() {
      this.region.cities.push({
        cityNameEng: this.region.cityNameEng,
        cityNameGeo: this.region.cityNameGeo,
      });
      this.region.cityNameEng = "";
      this.region.cityNameGeo = "";
    },
    resetForm() {
      this.region = {
        cityName: "",
        regionNameEng: "Georgia",
        regionNameGeo: "საქართველო",
        cities: [],
      };
    },
    async save() {
      const payload = {
        ...this.region,
      };
      payload.cities = payload.cities.map((city) => ({
        ...city,
      }));
      if (payload.cities.length) {
        try {
          await this.createRegion(payload);
          this.resetForm();
          this.getRegions();
          this.$bvModal.hide("create-modal");
        } catch (error) {
          console.log(error);
        }
      } else {
        Swal.fire({
          title: "დაფიქსირდა შეცდომა!",
          text: "შესანახად შეიყვანეთ რეგიონი",
          icon: "error",
          confirmButtonText: "Try again",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.inputs-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.b-form-tag .close {
  display: hide !important;
}
.city-container {
  flex-direction: column;
}
.padding-zero {
  padding-left: 0px;
}
</style>
