<template>
  <b-modal id="create-modal" ref="create-modal" hide-footer size="lg" centered>
    <AddRegions v-if="popupName === 'region'" />
    <AddAttributes v-if="popupName === 'attribute'" />
    <AddCategory v-if="popupName === 'category'" />
  </b-modal>
</template>

<script>
import AddRegions from "./AddRegions.vue";
import AddAttributes from "./components/AddOrEditAttributes.vue";
import AddCategory from "./AddCategory.vue";

export default {
  components: {
    AddRegions,
    AddAttributes,
    AddCategory,
  },
  props: {
    popupName: {
      type: String,
      default: "",
    },
  },
};
</script>
