<template>
  <div v-if="rg">
    <b-form-group :label="$t('regions.regionName')" label-for="region-name">
      <RegionsEdit
        :id="rg.regionId"
        :name-geo.sync="rg.regionNameGeo"
        :name-eng.sync="rg.regionNameEng"
        type="regions"
        :update-function="handleRegionParametersUpdate"
      />
    </b-form-group>
    <app-collapse
      v-for="item in rg.cities"
      :key="item.cityId"
      type="margin"
      accordion
    >
      <app-collapse-item :title="`${item.cityNameEng} / ${item.cityNameGeo}`">
        <RegionsEdit
          :id="item.cityId"
          :name-geo.sync="item.cityNameGeo"
          :name-eng.sync="item.cityNameEng"
          :coordinates.sync="item.coordinates"
          type="cities"
          :update-function="handleRegionParametersUpdate"
        />
        <b-button
          v-if="false"
          class="btn-icon"
          variant="flat-danger"
          @click="removeRegion(item.cityId, 'cities')"
        >
          {{ $t("regions.deleteCity") }}
          <feather-icon size="16" icon="TrashIcon" />
        </b-button>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import { BFormGroup, BButton } from "bootstrap-vue";
import "vue-good-table/dist/vue-good-table.css";
import { mapActions, mapState } from "vuex";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RegionsEdit from "@/views/parameters/components/RegionsEdit.vue";
import Swal from "sweetalert2";

export default {
  components: {
    BFormGroup,
    RegionsEdit,
    AppCollapse,
    BButton,
    AppCollapseItem,
  },
  data() {
    return {
      rg: null,
    };
  },

  computed: {
    ...mapState("parametersModule", ["region", "regions"]),
  },
  watch: {
    region(newVal) {
      if (newVal) this.rg = newVal;
    },
    regions(newVal) {
      if (this.rg) this.getRegion({ id: this.rg?.regionId });
    },
  },
  mounted() {
    this.getRegions();
  },
  methods: {
    ...mapActions("parametersModule", [
      "updateRegionParameters",
      "getRegions",
      "getRegion",
      "deleteRegionParameters",
    ]),
    toast(message = "success", type = "success") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant: type,
        },
      });
    },
    async handleRegionParametersUpdate(nameGeo, nameEng, id, type) {
      const payload = {
        nameGeo,
        nameEng,
        id,
        type,
      };
      try {
        await this.updateRegionParameters(payload);
        this.toast("Successfully updated", "success");
      } catch (err) {
        console.log(err);
      }
    },
    async removeRegion(id, type) {
      console.log(id, type);
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Accept it!",
      });

      if (result.isConfirmed) {
        try {
          await this.deleteRegionParameters({ type, id });
          this.toast("successfully Successfully", "success");
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  display: flex;
}

.districts-wrapper {
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.district {
  margin: 0 1rem 1rem 0;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0.5rem;
}
</style>
